import Layout from "components/layout"
import React, { useEffect, useState } from "react"
// import  { graphql } from  "gatsby"
import { apis as API } from "@sog/sdk"

// export const query = graphql`
//   {
//     gitCommit(latest: { eq: true }) {
//       hash
//       date
//     }
//   }
// `

const Index = () => {
  const [status, setStatus] = useState("")
  const [logText, setLogText] = useState("")

  useEffect(() => {
    API.status(responseJson => setStatus(responseJson.status))

    /*
    if (typeof console != "undefined")
      if (typeof console.log != "undefined") console.olog = console.log
      else console.olog = function () {}
  //  console.log = function (message) {
      setLogText(p => p + "\n" + message)
      console.olog(message)
    }
    console.error = console.debug = console.info = console.log

    window.onerror = (msg, url, line, col, error) => {
      // Note that col & error are new to the HTML 5 spec and may not be
      // supported in every browser.  It worked for me in Chrome.
      var extra = !col ? "" : "\ncolumn: " + col
      extra += !error ? "" : "\nerror: " + error

      // You can view the information in an alert to see things working like this:
      // console.error("Error: " + msg + "\nurl: " + url + "\nline: " + line + extra);
      console.error(`Error: ${msg} \nurl: ${url} \nline:  ${line} + ${extra}`)

      // TODO: Report this error via ajax so you can keep track
      //       of what pages have JS issues

      var suppressErrorAlert = true
      // If you return true, then error alerts (like in older versions of
      // Internet Explorer) will be suppressed.
      return suppressErrorAlert
    }

    window.onunhandledrejection = e => {
    //  console.log(`onunhandledrejection ${JSON.stringify(e, null, 2)}`)
      console.error(e)
      throw new Error(e.reason.stack)
    }
    setTimeout(() => {
      axios.interceptors.request.use(request => {
      //  console.log(`Starting Request ${JSON.stringify(request, null, 2)}`)
        return request
      })

      axios.interceptors.response.use(response => {
      //  console.log(`Response: ${JSON.stringify(response, null, 2)}`)
        return response
      })

    //  console.log(`process.env.REACT_APP_BASE_URL:  ${process.env.REACT_APP_BASE_URL}`)

      axios.post("/status/").then(
        response => {
        //  console.log(`Response:' ${JSON.stringify(response, null, 2)}`)
        //  console.log(`response.data: ${response.data}`)
        },
        error => {
          let jsonErr = {}
          if (error.response) jsonErr["response-data"] = error.response.data
          if (error.response) jsonErr["response-status"] = error.response.status
          if (error.response) jsonErr["response-headers"] = error.response.headers
          if (error.request) jsonErr["request"] = error.request
          if (error.message) jsonErr["message"] = error.message
        //  console.log(`error:  ${JSON.stringify(jsonErr, null, 2)}`)
        }
      )
    }, 2000)
      */
  }, [])

  return (
    <Layout>
      <div className="page-container">
        <div className="survey-container" style={{ whiteSpace: "pre-line" }}>
          <div>
            <table>
              <tr>
                <td> Website:&nbsp; </td>
                <td>
                  <code>
                    {process.env.GIT_INFO}
                    {/* {data.gitCommit.hash.substring(0, 7)} ({new Date(data.gitCommit.date).toLocaleString()}) */}
                  </code>
                </td>
              </tr>
              <tr>
                <td> API:&nbsp; </td>
                <td>
                  <code>{status}</code>
                </td>
              </tr>
            </table>
          </div>

          {logText}
        </div>
      </div>
    </Layout>
  )
}

export default Index
